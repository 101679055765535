const BLACK = "#000000";
const BLUE_98 = "#F7FAFD";
const BLUE = "#2C6496";
const BLUE_LIGHT = "#D8E6F3";
const BLUE_PRESSED = "#17354F";
const DARK_BLUE_HOVER = "#1d3e5e";
const DARK_GRAY = "#616161";
const DARK_RED = "#b50d0d";
const DARKEST_BLUE = "#0C1A27";
const GRAY = "#808080";
const LIGHT_GRAY = "#F2F2F2";
const MEDIUM_DARK_GRAY = "#D2D2D2";
const MEDIUM_LIGHT_GRAY = "#BDBDBD";
const TEAL_ACCENT = "#39C6C0";
const TEAL_LIGHT = "#F7FDFC";
const TEAL_PRESSED = "#227773";
const WHITE = "#FFFFFF";

const colors = {
  BLACK,
  BLUE_98,
  BLUE,
  BLUE_LIGHT,
  BLUE_PRESSED,
  DARK_BLUE_HOVER,
  DARK_GRAY,
  DARK_RED,
  DARKEST_BLUE,
  GRAY,
  LIGHT_GRAY,
  MEDIUM_DARK_GRAY,
  MEDIUM_LIGHT_GRAY,
  TEAL_ACCENT,
  TEAL_LIGHT,
  TEAL_PRESSED,
  WHITE,
};

export default colors;
